import React, { useState } from "react";
import { Input, Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import "antd/dist/reset.css";
import "../../styles/initiateLogin.scss";
import { isValidEmail } from "../../helpers/utils";
import { makeApiRequest } from "../../helpers/data";
import logo from "../../images/logo.png";
import { Form } from "react-bootstrap";
const Login = () => {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    if (!isValidEmail(email)) {
      message.error("Invalid Email Address");
      return;
    }

    // perfrom API call to check user credentials
    const promise = new Promise((resolve, reject) => {
      makeApiRequest(["auth/login"], "POST", {
        email,
        password,
      })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

    promise.then((res) => {
      if (res?.response?.status === 200) {
        localStorage.setItem("organizationName", res?.data?.organization_name);
        navigate("/welcome", {
          state: {
            organizationName: res?.data?.organization_name,
            username: res?.data?.user_name,
          },
        });
      } else {
        if (res?.error?.response?.data?.detail) {
          message.error(res?.error?.response?.data?.detail);
        }
      }
    });
  };

  return (
    <div className="login-container veriti_login--wrapper">
      <div className="veriti_login--inner">
        <h1>
          <img src={logo} alt="logo" className="img-fluid" />
        </h1>
        <h3>Login</h3>
        <p>Enter your credentials to continue with your account</p>
        <div className="form-container">
          <Form.Group
            className="form-group"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>Email ID</Form.Label>
            <Form.Control
              placeholder="Enter Email ID"
              onChange={handleEmailChange}
              className="input-field"
              type="email"
            />
          </Form.Group>
          <Form.Group
            className="form-group"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>Password</Form.Label>
            <Form.Control
              placeholder="Enter Password"
              type="password"
              onChange={handlePasswordChange}
              className="input-field"
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  handleSubmit();
                }
              }}
            />
          </Form.Group>

          <div className="login-link forgot-link">
            <span onClick={() => navigate("/forgotPassword")}>Forgot Password?</span>.
          </div>

          <Button
            type="primary"
            onClick={handleSubmit}
            className="submit-button btn-veriti"
          >
            Login
          </Button>

          <div className="login-link">
            Dont have an account?{" "}
            <span onClick={() => navigate("/")}>Sign up</span>.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
