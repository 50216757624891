import React, { useEffect, useRef, useState } from "react";
import { Spin, message } from "antd";
import "../../styles/welcome.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { makeApiRequest } from "../../helpers/data";
import logo from "../../images/logo-header.png";
import alert from "../../images/alert.png";
import delete_logo from "../../images/delete.png";
import hi from "../../images/hi.png";
import succesgif from "../../images/success.gif";
import loadergif from "../../images/loader.gif";
import { Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
const Welcome = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { organizationName, username } = state;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (title, description, buttonText) => {
    setModalContent({ title, description, buttonText });
    setShow(true);
  };
  const [loading, setLoading] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: '',
    description: '',
    buttonText: ''
  });
  const [Message, setMessage] = useState(
    `Please click the Start button to begin the Cybersecurity Analysis of <strong>${organizationName}</strong>.`
  );
  const [stepMessages, setStepMessages] = useState([]);

  const intervalIdRef = useRef(null); // useRef for storing interval ID

  const fetchData = async () => {
    const id = JSON.parse(localStorage.getItem("id"));
    const stringifiedID = localStorage.getItem("id");
    const apiEndpoint = [`llm_eng/orgSummary/${id}`];

    try {
      const response = await makeApiRequest(apiEndpoint, "GET");
      const responseData = response?.response?.data;

      const { status, step, total_steps, message } = responseData;

      if (
        status === "SCRAPE_WEBSITE" ||
        status === "GET_DNS_SSL_INFO" ||
        status === "SUMMARIZE" ||
        status === "GET_DMARK_INFO"
      ) {
        // Append the new message to the existing messages array
        setStepMessages((prevMessages) => [...prevMessages, message]);
        setMessage(`${stepMessages.join(" ")}`); // Join messages with a space
      } else if (status === "COMPLETE") {
        setMessage("Data has been fetched successfully!");
        clearInterval(intervalIdRef.current);
        navigate("/summary", {
          state: {
            data: responseData?.summary_dict,
            id: stringifiedID,
            created_at: responseData?.created_at,
          },
        });
      } else if (status === "FAILED") {
        setMessage(`Error: ${message}. Please try again.`);
        clearInterval(intervalIdRef.current);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setMessage(
        `Error fetching data for ${organizationName}. Please try again.`
      );
    }
  };

  const handleClick = () => {
    console.log("handle click called");
    setLoading(true);
    fetchData();

    // Set interval to check every 7 seconds
    intervalIdRef.current = setInterval(() => {
      fetchData();
    }, 7000);
  };

  useEffect(() => {}, [Message]);

  return (
    <div>
      <header className="veriti-header">
        <img className="img-fluid" src={logo} />
      </header>
      <div className="veriti-section-wrapper">
        <div className="veriti-section-inner">
          <div className="content-box p-0">
            <div className="start-button-container">
              <div className="welcome-message">
                <img src={hi} alt="hi" className="img-fluid" />
                <p>
                  Welcome <span>{username},</span>{" "}
                  <span>at {organizationName}</span>
                </p>
              </div>
              {/* <div>
                {stepMessages.map((stepMessage, index) => (
                  <p key={index}>{stepMessage}</p>
                ))}
                <p dangerouslySetInnerHTML={{ __html: Message }}></p>
              </div>
              {loading ? (
                <Spin size="large" />
              ) : (
                <button className="start-button" onClick={handleClick}>
                  START
                </button>
              )} */}
            </div>
          </div>
          <div className="content-box">
            
            <ul className="summary-list">
              <li>
                <h4>Re-Generate Company Summary</h4>
                <Button className="btn-veriti"  onClick={() => handleShow('Re-Generate Company Summary?', 'This will delete existing company summary', 'Proceed')}>
                  Generate
                </Button>
              </li>
              <li>
                <h4>View Company Summary</h4>
                <Button className="btn-veriti disabled-btn">View</Button>
              </li>
              <li>
                <h4>(Optional) Edit Company Profile</h4>
                <Button className="btn-veriti disabled-btn">Edit</Button>
              </li>
              <li>
                <h4>Open-Source Intelligence Gathering</h4>
                <Button className="btn-veriti disabled-btn" onClick={() => handleShow('User is authorized to conduct scans against their website.', '', 'Go to gather function')}>Gather</Button>
              </li>
            </ul>
          </div>
          <div className="content-box">
            <Table striped bordered hover>
              <tbody>
                <tr>
                  <th>Cybersecurity Report</th>
                  <td>07/07/2024,04:45PM</td>
                  <td>
                    {" "}
                    <div className="btn_wrapper">
                      <Button className="btn-veriti">View</Button>
                      <Button className="btn-veriti">Edit</Button>
                      <Button onClick={() => handleShow('Delete?', 'Are you sure you want to delete?', 'Delete')} className="btn-veriti">Delete</Button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Cybersecurity Report</th>
                  <td>07/07/2024,04:45PM</td>
                  <td>
                    {" "}
                    <div className="btn_wrapper">
                      <Button className="btn-veriti">View</Button>
                      <Button className="btn-veriti">Edit</Button>
                      <Button onClick={() => handleShow('Delete?', 'Are you sure you want to delete?', 'Delete')} className="btn-veriti">Delete</Button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Cybersecurity Report</th>
                  <td>07/07/2024,04:45PM</td>
                  <td>
                    {" "}
                    <div className="btn_wrapper">
                      <Button className="btn-veriti">View</Button>
                      <Button className="btn-veriti">Edit</Button>
                      <Button onClick={() => handleShow('Delete?', 'Are you sure you want to delete?', 'Delete')} className="btn-veriti">Delete</Button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Cybersecurity Report</th>
                  <td>07/07/2024,04:45PM</td>
                  <td>
                    {" "}
                    <div className="btn_wrapper">
                      <Button className="btn-veriti">View</Button>
                      <Button className="btn-veriti">Edit</Button>
                      <Button onClick={() => handleShow('Delete?', 'Are you sure you want to delete?', 'Delete')} className="btn-veriti">Delete</Button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
            <Button className="btn-veriti btn-bottom" onClick={handleClick}>
              Generate New Cybersecurity Report
            </Button>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <img src={modalContent.buttonText === "Delete" ? delete_logo : alert} className="img-fluid" alt="Alert" />
          <h3>{modalContent.title}</h3>
          {modalContent.description && <p>{modalContent.description}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button className={`${modalContent.buttonText === "Delete" ? "btn-danger" : ""}`} variant="secondary" onClick={handleClose}>
            {modalContent.buttonText}
          </Button>
          <Button  variant="primary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
  
    
    </div>
  );
};

export default Welcome;
