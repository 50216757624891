import React, { useState } from "react";
import { Input, Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import "antd/dist/reset.css";
import "../../styles/initiateLogin.scss";
import { isValidEmail } from "../../helpers/utils";
import { makeApiRequest } from "../../helpers/data";
import logo from "../../images/logo.png";
import { Form } from "react-bootstrap";
const SelectPlan = () => {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    if (!isValidEmail(email)) {
      message.error("Invalid Email Address");
      return;
    }

    // perfrom API call to check user credentials
    const promise = new Promise((resolve, reject) => {
      makeApiRequest(["auth/login"], "POST", {
        email,
        password,
      })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });

    promise.then((res) => {
      if (res?.response?.status === 200) {
        localStorage.setItem("organizationName", res?.data?.organization_name);
        navigate("/welcome", {
          state: {
            organizationName: res?.data?.organization_name,
            username: res?.data?.user_name,
          },
        });
      } else {
        if (res?.error?.response?.data?.detail) {
          message.error(res?.error?.response?.data?.detail);
        }
      }
    });
  };

  return (
    <div className="login-container veriti_login--wrapper verit_price_wrapper">
      <div className="veriti_login--inner">
        <h1>
          <img src={logo} alt="logo" className="img-fluid" />
        </h1>
        <h3>Select plan</h3>
        <p>Simple and flexible per user pricing</p>
        <div className="row">
          <div className="col-lg-6">
            <div className="price-card-wrapper">
              <h3>Standard</h3>
              <h4>$29<span>/month</span></h4>
              <h6>Plan Benefits</h6>
              <ul>
                <li>Download reports</li>
                <li>1 Time cybersecurity reports generation</li>
              </ul>
              <button className="ant-btn css-dev-only-do-not-override-p7e5j5 ant-btn-primary submit-button btn-veriti">Buy Plan</button>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="price-card-wrapper">
              <h3>Pro</h3>
              <h4>$49<span>/month</span></h4>
              <h6>Plan Benefits</h6>
              <ul>
                <li>Download & get reports on email</li>
                <li>3 Time cybersecurity reports generation</li>
              </ul>
              <button className="ant-btn css-dev-only-do-not-override-p7e5j5 ant-btn-primary submit-button btn-veriti">Buy Plan</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectPlan;
