import React, { useState } from "react";
import { Input, Button, message, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import "antd/dist/reset.css";
import "../../styles/initiateLogin.scss";
import { isValidEmail } from "../../helpers/utils";
import { makeApiRequest } from "../../helpers/data";
import logo from "../../images/logo.png";
import succesgif from "../../images/success.gif";
import { Form } from "react-bootstrap";

const InitiateLogin = () => {
  const navigate = useNavigate();

  // State for name and email
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [url, setUrl] = useState("");

  const handleInputChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    if (!isValidEmail(email)) {
      message.error("Invalid Email Address");
      return;
    }

    setLoading(true); // Set loading to true when submitting

    const promise = new Promise((resolve, reject) => {
      makeApiRequest(["auth/register"], "POST", { name, email })
        .then((response) => resolve(response))
        .catch((error) => reject(error))
        .finally(() => setLoading(false)); // Set loading to false regardless of success or failure
    });

    promise.then((res) => {
      setUrl(res?.response?.data?.password_url);
      const responseStatus = res?.response?.status;
      if (responseStatus === 200) {
        setSuccess(true);
      } else {
        if (res?.error?.response?.data?.detail) {
          message.info(res?.error?.response?.data?.detail);
        } else {
          message.error("Something Went Wrong");
        }
      }
    });
  };

  return (
    <div className="login-container veriti_login--wrapper">
      <div className="veriti_login--inner">
        <h1>
          <img src={logo} alt="logo" className="img-fluid" />
        </h1>
        {loading ? (
          <Spin size="large" />
        ) : !success ? (
          <>
            <h3>Sign up</h3>
            <p>Enter your details and get started with us</p>
            <div className="form-container">
              <Form.Group
                className="form-group"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  placeholder="Enter Full Name"
                  onChange={handleInputChange}
                  className="input-field"
                />
              </Form.Group>
              <Form.Group
                className="form-group"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Email ID</Form.Label>
                <Form.Control
                  placeholder="Enter Email ID"
                  type="email"
                  onChange={handleEmailChange}
                  className="input-field"
                  onKeyDown={(e) => {
                    if (e.code === "Enter") {
                      handleSubmit();
                    }
                  }}
                />
              </Form.Group>
              <Button
                type="primary"
                onClick={handleSubmit}
                className="submit-button btn-veriti"
              >
                Sign Up
              </Button>
              <div className="login-link">
                Already have an account?{" "}
                <span onClick={() => navigate("/login")}>Log in</span>
              </div>
              <div className="login-link">
                Debug<span onClick={() => navigate("/clear")}>Remove User</span>
                .
              </div>
            </div>
          </>
        ) : (
          <>
            <h3>Email sent</h3>
            <img src={succesgif} alt="success" className="img-fluid success-gif"/>
            <p>Please check your provided email for verification & create a password for your account.</p>
            <div className="success-message">
              <a href={url} target="_blank" rel="noopener noreferrer">
                {url || "http://hdufgaeifgrewhfbehfbeef/auesirhkberhbtwb/"}
              </a>
            </div>
              <Button className="btn-veriti btn-secondary" onClick={() => navigate("/login")}>Back to login</Button>.
          </>
        )}
      </div>
    </div>
  );
};

export default InitiateLogin;
