export const isValidEmail = (email) => {
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const isValidPassword = (password, confirmPassword) => {
    // Password validation regex
    const passwordRegex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;

    // Check if the password meets the criteria
    return passwordRegex.test(password) && password === confirmPassword;
};
