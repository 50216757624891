import axios from "axios";

/**
 * Makes a generic API request using Axios.
 * @param {string[]} path - An array representing the path segments of the API endpoint.
 * @param {string} method - HTTP method for the request (GET, POST, PUT, PATCH, DELETE, etc.).
 * @param {object|null} data - Data to be sent with the request (optional).
 * @returns {Promise<{ data: any, response?: object, error?: any }>} - Resolves with response data or an error object.
 */
export async function makeApiRequest(path, method = "GET", data = null) {
  const baseURL = "http://64.23.148.248:8000/" ;
  //http://165.22.176.112:8000/
  //10.164.2.45
  //165.22.176.112
  const apiUrl = `${baseURL}${path.join("/")}`;

  console.log(">>> -> file: data.js:14 -> makeApiRequest -> apiUrl", apiUrl);

  const options = {
    method,
    url: apiUrl,
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (data) {
    // Create a FormData object
    const formData = new FormData();

    // Append data properties to the FormData object
    for (const key in data) {
      formData.append(key, data[key]);
    }

    // Use the FormData object as the request data
    options.data = formData;
  }

  try {
    const response = await axios(options);
    return { data: response.data, response };
  } catch (error) {
    console.error(`Error making ${method} request to ${apiUrl}:`, error);
    return { error };
  }
}
