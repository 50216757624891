import React, { useState, useEffect } from "react";
import { Typography, Spin, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { makeApiRequest } from "../../helpers/data";
import Markdown from "react-markdown";
import html2pdf from "html2pdf.js";
import logo from "../../images/logo-header.png";
import summaryLogo from "../../images/summary-logo.svg";
import "../../styles/FinalSummary.scss";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import loadergif from "../../images/loader.gif";

const { Title, Paragraph } = Typography;

const FinalSummaryII = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { answers, id } = state || {};
  const userId = JSON.parse(localStorage.getItem("id"));
  const [summaryData, setSummaryData] = useState("");
  const [showMailButton, setShowMailButton] = useState(true);
  const [loading, setLoading] = useState(false); // Set initial loading state to false
  const [countdown, setCountdown] = useState(300); // 180 seconds = 3 minutes
  const [isEmailSent, setIsEmailSent] = useState(false);
  // const { organizationName } = state;
  const organizationName = "test"
  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 0) {
          clearInterval(interval);
        }
        return prevCountdown - 1;
      });
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const generateFinalSummary = async () => {
      try {
        const finalResponse = await makeApiRequest(
          [`llm_eng/getReport/${id}`],
          "GET"
        );

        // Implement additional logic if needed
        if (finalResponse?.data) {
          if (finalResponse?.data?.Status === "FAILED") {
            setSummaryData(
              "# No report found for this user. Please try again."
            );
            return;
          }
          setSummaryData(finalResponse?.data.Report);
          setShowMailButton(true);
        }
      } catch (error) {
        console.error("Error generating final summary:", error);
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };

    generateFinalSummary(); // Call the asynchronous function
  }, []);

  const exportToPDF = () => {
    const element = document.getElementsByClassName("summary-item")[0];
    html2pdf(element, {
      margin: 0.75,
      filename: `cybersecurity-report-${organizationName}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    });
  };

  const handleSendEmail = () => {
    // Simulate email sending
    setTimeout(() => {
      setIsEmailSent(true);
      message.success("Email sent!");
    }, 1000);
  };

  return (
    <>
      <header className="veriti-header">
        <img className="img-fluid" src={logo} />
        <ul className="veriti-stepper">
          <li>
            <span>1</span>
            <h5>Company Summary</h5>
          </li>
          <li>
            <span>2</span>
            <h5>Questionnaire 1</h5>
          </li>
          <li>
            <span>3</span>
            <h5>Questionnaire 2</h5>
          </li>
          <li className="active">
            <span>4</span>
            <h5>Cybersecurity Report</h5>
          </li>
        </ul>
      </header>
      {loading && (
        <Modal show={loading} centered>
          <Modal.Body>
            <h3>Generating Cybersecurity Profile</h3>
            <p>Please wait...</p>
            <h5>
              {countdown}
              <span> sec</span>
            </h5>
            <img
              src={loadergif}
              alt="success"
              className="img-fluid success-gif loader-gif"
            />
          </Modal.Body>
        </Modal>
      )}
      {!loading && (
        <>
          <div className="veriti-section-wrapper veriti-summary-wrapper">
            <div className="veriti-section-inner">
              <div className="container mb-4">
                <div className="content-box p-0">
                  <div className="final-summary-container-new editable-summary-container position-relative">
                    <img src={summaryLogo} class="summary-logo" alt="logo" />
                    <Markdown>{summaryData}</Markdown>
                    <h1>Detail Report Heading</h1>
                    <p>Detail Report Content Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                  </div>
                </div>
              </div>
              {/* <div className="container-fluid">
                <div className="content-box p-0">
                  <div
                    className="button-container btn-container-secondary
"
                  >
                    <div className="btn-wrapper">
                      {showMailButton && (
                        <>
                          <Button
                            type="primary"
                            title="Email me the full report"
                            className="btn-veritig-white  ant-btn css-dev-only-do-not-override-p7e5j5 ant-btn-primary btn-veriti"
                            onClick={handleSendEmail}
                          >
                            {isEmailSent
                              ? "Email sent ✔ "
                              : "Email me the full report"}
                            <span className="mail"></span>
                          </Button>
                          <Button
                            type="primary"
                            title="Download the Full Report"
                            className="btn-veritig-white  ant-btn css-dev-only-do-not-override-p7e5j5 ant-btn-primary btn-veriti"
                            onClick={exportToPDF}
                          >
                            Download the Full Report
                            <span className="download-white"></span>
                          </Button>
                        </>
                      )}
                    </div>
                    <div className="btn-wrapper ">
                      {showMailButton && (
                        <>
                          <Button
                            type="primary"
                            className="btn-veritig-white  ant-btn css-dev-only-do-not-override-p7e5j5 ant-btn-primary btn-veriti"
                            onClick={handleShow}
                          >
                            Generate Detailed Recommendation Report
                            <span className="report"></span>
                          </Button>
                          <Button
                            type="primary"
                            className="btn-veritig-white  ant-btn css-dev-only-do-not-override-p7e5j5 ant-btn-primary btn-veriti"
                          >
                            Create an IT Policy Manual
                            <span className="manual"></span>
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <></>
          </div>
   
        </>
      )}
    </>
  );
};

export default FinalSummaryII;
